// src/types/index.ts
export interface UserDataRequestParam {
  uid: string;
  userId: string;
  userName: string;
  phoneNumber: string;
  deleteAuthWhenNotJoined: boolean;
}
export interface CreditSubtractRequestParam {
  userId: string;
  creditsToSubtract: number;
}

export interface ScriptHelperCreateParam {
  lang: string;
  assistantId: string;
  lastCategoryUpdated: string;
}

export interface ScriptHelperUpdateParam {
  lang: string;
  lastCategoryUpdated: string;
}

export interface NewKeywordHelperUpdateParam {
  userId: string;
  threadId: string;
}

export interface TypoCorrectionHelperUpdateParam {
  userId: string;
  threadId: string;
}

export interface VideoClipSelectorUpdateParam {
  userId: string;
  threadId: string;
}

export interface MetaCompleterCreateParam {
  lang: string;
  assistantId: string;
  lastCategoryUpdated: string;
}

export interface MetaCompleterUpdateParam {
  lang: string;
  lastCategoryUpdated: string;
}

// export interface ScriptHelperRequestParam {
//   userId: string;
//   lang: string;
// }
// export interface MetaCompleterRequestParam {
//   userId: string;
//   lang: string;
// }

export interface CreditUsableRequestParam {
  userId: string;
}

export interface CreditUsableResponse {
  currentCredits: number;
}

export interface PaymentType {
  amount: number;
  period: number;
  unit: string;
}

export interface UserDataResponse {
  IsJoined: boolean;
  UID: string;
  CreateTime: string;
  Email: string;
  // Name: string;
  // PhoneNumber: string;
  LastPaymentAmount: number;
  LastPaymentCurrency: string;
  LastPaymentDateTime: string;
  NextPaymentAmount: number;
  NextPaymentCurrency: string;
  NextPaymentDateTime: string;
  PaymentMethod: string;
  PromotionId: string;
  SubscriptionStartDateTime: string;
  SubscriptionStatus: string;
  SubscriptionType: string;
  UserLevel: number;
  HasSubscribed: boolean;
  StopSubscription: boolean;
  Credits: number;
  Channels: string[];
}

export interface TextToVideoType {
  theme: string;
  voiceType: VoiceType;
}

export interface BestSeriesType {
  theme: string;
  bests: string[];
  bestsCountForAuto: number;
  entryMode: string;
  bestCountForAuto: number;
  includeOpening: boolean;
  includeClosing: boolean;
  useRanking: boolean;
  voiceType: VoiceType;
}

export interface VideoToVideoType {
  videoUrl: string;
  useFixedSubtitle: boolean;
  fixedSubtitle: string;
  cropStartTime: string;
  cropEndTime: string;
}

export interface VideoTask {
  id: string;
  channel: string;
  taskType: TaskType;
  videoLength: VideoLength;
  status: TaskStatus;
  updatedDateTime: string;
  textToVideo: TextToVideoType;
  bestSeries: BestSeriesType;
  videoToVideo: VideoToVideoType;
}

export enum VideoLength {
  Short = "short",
  Long = "long",
}

export enum TaskType {
  VideoToVideo = "videoToVideo",
  TextToVideo = "textToVideo",
  BestSeries = "bestSeries",
}

export enum TaskStatus {
  Completed = "completed",
  Pending = "pending",
  InProgress = "inProgress",
  Failed = "failed",
  Canceled = "canceled",
}

export enum VoiceType {
  Free = "free",
  Pro = "pro",
}
