import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./CustomPagination.module.css"; // CSS 모듈 임포트

function CustomPagination({
  page,
  onPageChange,
}: {
  page: number;
  onPageChange: (page: number) => void;
}) {
  const { t } = useTranslation(); // 다국어 번역 함수 가져오기

  return (
    <div className={styles.pagination}>
      <Button
        onClick={() => onPageChange(1)}
        disabled={page === 1}
        aria-label={t("pagination.first")}
      >
        {t("pagination.first")}
      </Button>
      <Button
        onClick={() => onPageChange(page - 1)}
        disabled={page === 1}
        aria-label={t("pagination.previous")}
      >
        {t("pagination.previous")}
      </Button>
      <Button
        onClick={() => onPageChange(page + 1)}
        aria-label={t("pagination.next")}
      >
        {t("pagination.next")}
      </Button>
    </div>
  );
}

export default CustomPagination;
