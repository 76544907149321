import "./LoginEmail.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase-config";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { useUser } from "../contexts/UserContext";
import { FirebaseError } from "firebase/app";

const LoginEmail = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const { loginOption } = useUser() ?? {};

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (loginOption?.isRegisterMode) {
      if (password !== confirmPassword) {
        alert("Passwords do not match.");
        return;
      }
      try {
        await createUserWithEmailAndPassword(auth, email, password);        
        navigate("/");
      } catch (error) {
        if (error instanceof Error) {
          alert(error.message);
        } else {
          alert("An error occurred while registering.");          
        }
      }
    } else {
      try {
        await signInWithEmailAndPassword(auth, email, password);
        navigate("/");
      } catch (error) {
        if (error instanceof FirebaseError) {
          if (error.code === "auth/invalid-credential") {
            alert("이메일 주소 또는 비밀번호가 올바르지 않습니다.");
          } else {
            alert(error.message);
          }
        } else {
          alert("An error occurred while signing in.");
        }
      }
    }    
  };

  return (
    <div className="login-email-page">
      <div className="login-container">
        {loginOption?.isRegisterMode ? <h1>AUTOVID Register</h1> : <h1>AUTOVID Login</h1>}
        <form onSubmit={handleLogin} className="login-form">
          <input
            type="email"
            className="login-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
          />
          <input
            type="password"
            className="login-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
          />
          {loginOption?.isRegisterMode && (
            <input
              type="password"
              className="login-input"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm your password"
            />
          )}
          <button type="submit" className="login-button">
            {loginOption?.isRegisterMode ? "Register" : "Sign in"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginEmail;