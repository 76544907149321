import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./UserMenu.module.css";
import { useTranslation } from "react-i18next"; // i18next import

interface UserMenuProps {
  userId: string;
  subscriptionType: string;
  handleLogin: () => void;
  handleLogout: () => void;
}

const UserMenu: React.FC<UserMenuProps> = ({
  userId,
  subscriptionType,
  handleLogin,
  handleLogout,
}) => {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const currentLang = i18n.language; // 현재 설정된 언어 가져오기
  const onClickLogout = () => {
    handleLogout();
    setIsUserMenuOpen(false);
  };

  const onClickMyPage = () => {
    navigate(`/${currentLang}/myPage`);
    setIsUserMenuOpen(false);
  };

  const onClickLogin = () => {
    handleLogin();
    setIsUserMenuOpen(false);
  };

  const onClickJoinIn = () => {
    navigate(`/${currentLang}/joinIn`);
    setIsUserMenuOpen(false);
  };

  return (
    <div className={styles.userMenu}>
      <button
        className={styles.button}
        onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
      >
        {t("user_menu.account_management")}
      </button>
      <div
        className={`${styles.menuLinks} ${isUserMenuOpen ? styles.open : ""}`}
      >
        {userId !== "Guest" ? (
          <>
            <button className={styles.submenuButton} onClick={onClickMyPage}>
              {t("user_menu.my_page")}
            </button>
            <button className={styles.submenuButton} onClick={onClickLogout}>
              {t("user_menu.logout")}
            </button>
          </>
        ) : (
          <>
            <button className={styles.submenuButton} onClick={onClickLogin}>
              {t("user_menu.login")}
            </button>
            <button className={styles.submenuButton} onClick={onClickJoinIn}>
              {t("user_menu.join")}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default UserMenu;
