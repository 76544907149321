import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "ko", "ja"], // 지원하는 언어 코드
    fallbackLng: "en", // 선택된 언어가 없을 경우 사용할 언어
    debug: false, // 디버그 모드 활성화
    interpolation: {
      escapeValue: false, // React는 기본적으로 XSS를 방지하므로 이 옵션을 false로 설정
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json", // 언어별 JSON 파일 경로
    },
  });

export default i18n;
