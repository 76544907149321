import React, { useEffect, useState } from "react";
import styles from "./InquiryDetail.module.css";
import { UserContextType } from "../contexts/UserContext";
import { useParams } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ReplyItem from "./ReplyItem";
import { useUser } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import {
  getFirestore,
  doc,
  addDoc,
  getDoc,
  deleteDoc,
  collection,
  getDocs,
  query,
  orderBy,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
} from "firebase/firestore";
import { Typography, List, Divider } from "@mui/material";
import { useTranslation } from "react-i18next"; // 추가된 부분

type Reply = {
  id: string;
  isAdmin: boolean;
  authorId: string;
  content: string;
  timestamp: Date;
};

const InquiryDetail = () => {
  const { i18n, t } = useTranslation();
  const { inquiryId } = useParams();
  const [inquiry, setInquiry] = useState<DocumentData | null>(null);
  const [replies, setReplies] = useState<Reply[]>([]);
  const [loading, setLoading] = useState(false);
  const [replyText, setReplyText] = useState("");
  const navigate = useNavigate();
  const userContext = useUser();
  const currentLang = i18n.language; // 현재 설정된 언어 가져오기
  useEffect(() => {
    if (userContext === null) {
      return;
    }

    const fetchInquiry = async () => {
      setLoading(true);
      if (typeof inquiryId !== "string") {
        return;
      }

      const db = getFirestore();
      const inquiryRef: DocumentReference<DocumentData, DocumentData> | null =
        doc(db, "inquiries", inquiryId) ?? null;
      const inquiryDoc: DocumentSnapshot = await getDoc(inquiryRef);

      if (inquiryDoc.exists()) {
        setInquiry(inquiryDoc.data());
        const repliesRef = collection(inquiryRef, "replies");
        const sortedRepliesRef = query(repliesRef, orderBy("timestamp", "asc"));
        const replySnapshot = await getDocs(sortedRepliesRef);

        const repliesData = replySnapshot.docs.map((doc) => ({
          id: doc.id,
          isAdmin: doc.data().isAdmin,
          authorId: doc.data().authorId,
          content: doc.data().content,
          timestamp: doc.data().timestamp.toDate(),
        }));
        setReplies(repliesData);
      } else {
        setInquiry(null);
      }
      setLoading(false);
    };

    fetchInquiry();
  }, [inquiryId, userContext]);

  if (loading) {
    return <Typography>{t("inquiry_detail.loading")}</Typography>; // 변경된 부분
  }

  if (!inquiry) {
    return (
      <Typography variant="h6">{t("inquiry_detail.not_found")}</Typography>
    ); // 변경된 부분
  }

  const handleAddReply = async () => {
    const db = getFirestore();

    if (typeof inquiryId !== "string") {
      return;
    }

    const trimmedReplyText = replyText.trim();
    if (trimmedReplyText.length === 0) {
      alert(t("inquiry_detail.alert_enter_reply")); // 변경된 부분
      return;
    }

    const repliesCollectionRef = collection(
      db,
      "inquiries",
      inquiryId,
      "replies"
    );
    const { userDetails } = userContext as UserContextType;
    try {
      const writeTime = new Date();
      const newReplyRef = await addDoc(repliesCollectionRef, {
        authorId: userDetails.id,
        isAdmin: userDetails.userLevel <= 1,
        content: trimmedReplyText,
        timestamp: writeTime,
      });

      setReplies((prevReplies) => [
        ...prevReplies,
        {
          id: newReplyRef.id,
          isAdmin: userDetails.userLevel <= 1,
          content: trimmedReplyText,
          authorId: userDetails.id,
          timestamp: writeTime,
        },
      ]);

      setReplyText("");
    } catch (error) {
      console.error("Error adding reply:", error);
    }
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm(t("inquiry_detail.confirm_delete")); // 변경된 부분
    if (!confirmDelete) {
      return;
    }
    const db = getFirestore();

    if (typeof inquiryId !== "string") {
      return;
    }

    const docRef: DocumentReference<DocumentData, DocumentData> | null = doc(
      db,
      "inquiries",
      inquiryId
    ) as DocumentReference<DocumentData, DocumentData>;

    if (docRef === null) {
      return;
    }

    try {
      await deleteDoc(docRef);
      navigate(`/${currentLang}/inquiries`);
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const handleDeleteReply = async (replyId: string) => {
    const confirmDelete = window.confirm(
      t("inquiry_detail.confirm_delete_reply")
    ); // 변경된 부분
    if (!confirmDelete) {
      return;
    }
    const db = getFirestore();

    if (typeof inquiryId !== "string") {
      return;
    }

    const replyRef: DocumentReference<DocumentData, DocumentData> | null = doc(
      db,
      "inquiries",
      inquiryId,
      "replies",
      replyId
    ) as DocumentReference<DocumentData, DocumentData>;

    if (replyRef === null) {
      return;
    }

    try {
      await deleteDoc(replyRef);
      const updatedReplies = replies.filter((reply) => reply.id !== replyId);
      setReplies(updatedReplies);
    } catch (error) {
      console.error("Error deleting reply:", error);
    }
  };

  let displayName: string;
  displayName =
    inquiry.authorId.charAt(0) + "*".repeat(inquiry.authorId.length - 1);

  let shouldShowReplyButton: boolean = false;
  let isAdmin = false;
  if (userContext !== null) {
    const { userDetails } = userContext as UserContextType;
    shouldShowReplyButton =
      (userDetails.id !== "Guest" && userDetails.userLevel <= 1) ||
      userDetails.id === inquiry.authorId;
    isAdmin = userDetails.userLevel <= 1;
    if (isAdmin) {
      displayName = t("inquiry_detail.admin");
    }
  }

  return (
    <div className={styles.container}>
      <Typography variant="h5" className={styles.title}>
        {t("inquiry_detail.board_title")} {/* 변경된 부분 */}
      </Typography>
      <Typography variant="subtitle2" className={styles.subject}>
        {inquiry.title}
      </Typography>
      <div className={styles.info}>
        <Typography variant="subtitle2" className={styles.author}>
          {t("inquiry_detail.author")}: {displayName} {/* 변경된 부분 */}
        </Typography>
        <Typography variant="subtitle2" className={styles.date}>
          {t("inquiry_detail.date")}:{" "}
          {new Date(inquiry.timestamp.toDate()).toLocaleString()}{" "}
          {/* 변경된 부분 */}
        </Typography>
      </div>
      <Typography variant="body1" className={styles.content}>
        {inquiry.content}
      </Typography>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={styles.grid}
      >
        <Grid item style={{ flexGrow: 1 }} className="flex-container">
          <Button
            variant="contained"
            color="info"
            style={{ float: "left" }}
            onClick={() => navigate(`/${currentLang}/inquiries`)}
          >
            {t("inquiry_detail.back_button")} {/* 변경된 부분 */}
          </Button>
        </Grid>
        {shouldShowReplyButton && (
          <Grid item style={{ flexGrow: 1 }} className="flex-container">
            <Button
              variant="contained"
              color="secondary"
              style={{ float: "right" }}
              onClick={handleDelete}
            >
              {t("inquiry_detail.delete_button")} {/* 변경된 부분 */}
            </Button>
          </Grid>
        )}
      </Grid>
      <List>
        {replies.map((reply) => (
          <React.Fragment key={reply.id}>
            <ReplyItem reply={reply} onDelete={handleDeleteReply} />
            <Divider />
          </React.Fragment>
        ))}
      </List>

      {shouldShowReplyButton && (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={styles.grid}
        >
          <Grid item xs={12}>
            <TextareaAutosize
              value={replyText}
              area-aria-multiline="true"
              className="replyItem"
              onChange={(e) => setReplyText(e.target.value)}
              aria-label="minimum height"
              minRows={3}
              placeholder={t("inquiry_detail.reply_placeholder")} // 변경된 부분
              style={{ width: "100%", resize: "none" }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: "10px" }}
              onClick={handleAddReply}
            >
              {t("inquiry_detail.add_reply_button")} {/* 변경된 부분 */}
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default InquiryDetail;
