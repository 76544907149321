import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./LanguageSwitcher.module.css";

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (language: string) => {
    // 현재 경로에서 언어를 변경하여 URL을 업데이트
    const currentPath = location.pathname.split("/").slice(2).join("/");
    i18n.changeLanguage(language);
    navigate(`${language}/${currentPath}`);
    setIsOpen(false); // 메뉴를 닫음
  };

  return (
    <div className={styles.languageSwitcherContainer}>
      <button onClick={toggleMenu} className={styles.languageButton}>
        Languages
      </button>
      {isOpen && (
        <div className={styles.languageMenu}>
          <button
            onClick={() => changeLanguage("en")}
            className={styles.languageMenuItem}
          >
            English
          </button>
          <button
            onClick={() => changeLanguage("ko")}
            className={styles.languageMenuItem}
          >
            한국어
          </button>
          <button
            onClick={() => changeLanguage("ja")}
            className={styles.languageMenuItem}
          >
            日本語
          </button>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
