// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import { initializeApp, FirebaseApp } from "firebase/app";
import { getAuth, Auth } from "firebase/auth";

// Define the type for Firebase configuration
interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

// Your web app's Firebase configuration
const firebaseConfig: FirebaseConfig = {
  apiKey: "AIzaSyA67_l4cp0tyI_qF7tqbqujFz2gww87Y8I",
  authDomain: "autovid.net",
  projectId: "dreamcraft-389613",
  storageBucket: "dreamcraft-389613.appspot.com",
  messagingSenderId: "207770230402",
  appId: "1:207770230402:web:6040c6bb3885cacb2b5e9b",
};

// Initialize Firebase
const app: FirebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
const auth: Auth = getAuth(app);

// Export the authentication and Firebase app objects
export { auth, app };
export default firebase;
