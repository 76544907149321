import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { UserContextType } from "../contexts/UserContext";
import styles from "./MyPage.module.css";
import { useUser } from "../contexts/UserContext";
import { signOut } from "firebase/auth";
import { auth } from "./firebase-config"; // Firebase 앱 초기화 설정 임포트
import { withdrawMember } from "../components/firebaseFunctions";
import { useTranslation } from "react-i18next"; // i18next import

const MyPage = () => {
  const { t } = useTranslation(); // useTranslation 훅 사용
  const navigate = useNavigate();
  const userContext = useUser(); // Remove type argument from useUser function call
  const { i18n } = useTranslation();
  const currentLang = i18n.language; // 현재 설정된 언어 가져오기

  if (userContext === null) {
    return null;
  }
  const { userDetails } = userContext as UserContextType;

  const handleLogout = async () => {
    await signOut(auth);
    navigate(`/${currentLang}/`);
  };

  const handleAccountDeletion = async () => {
    const msg =
      userDetails.subscriptionType !== "Free"
        ? t("my_page.account_deletion_confirm_with_subscription")
        : t("my_page.account_deletion_confirm");

    if (!window.confirm(msg)) {
      return;
    }

    try {
      await withdrawMember(userDetails.id);
      handleLogout();
      navigate(`/${currentLang}/`);
    } catch (error) {
      console.error(t("my_page.account_deletion_error"), error);
      alert(t("my_page.account_deletion_failed"));
    }
  };

  return (
    <div className={styles.myPage}>
      <h1>{t("my_page.title")}</h1>
      <div className={styles.userDetails}>
        <div>{t("my_page.id")}:</div>
        <div>{userDetails.id}</div>
        <div>{t("my_page.plan")}:</div>
        <div>{userDetails.subscriptionType}</div>
        <div>{t("my_page.credits")}:</div>
        <div>{userDetails.credits.toFixed(2)}</div>
      </div>
      <div className={styles.buttonGroup}>
        <button
          onClick={() => navigate(`/${currentLang}/task-management`)}
          className={styles.buttonNormal}
        >
          {t("my_page.task_management")}
        </button>
        <button onClick={handleLogout} className={styles.buttonNormal}>
          {t("my_page.logout")}
        </button>
      </div>
      <div className={styles.legalLinks}>
        <Link to={`/${currentLang}/terms-of-service`}>
          {t("my_page.terms_of_service")}
        </Link>
        <Link to={`/${currentLang}/privacy-policy`}>
          {t("my_page.privacy_policy")}
        </Link>
      </div>
      <div className={styles.accountDeletion} onClick={handleAccountDeletion}>
        {t("my_page.account_deletion")}
      </div>
    </div>
  );
};

export default MyPage;
