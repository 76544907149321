import React from "react";
import MobileMenu from "./MobileMenu";
import UserMenu from "./UserMenu";
import NavigationLinks from "./NavigationLinks";
import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserContext";
import { signOut } from "firebase/auth";
import { auth } from "./firebase-config";
import styles from "./UserStatus.module.css";
import { useTranslation } from "react-i18next"; // 추가된 부분

const UserStatus: React.FC = () => {
  const navigate = useNavigate();
  const { userDetails, setLoginOption } = useUser() ?? {};
  const { i18n } = useTranslation();
  const currentLang = i18n.language; // 현재 설정된 언어 가져오기
  const handleLogout = async () => {
    await signOut(auth);
    navigate(`/${currentLang}`);
  };

  const handleLogin = () => {
    const loginOption = {
      isRegisterMode: false,
      isTermsOfServiceAgreed: false,
      isPrivacyPolicyAgreed: false,
      userName: "",
      phoneNumber: "",
    };
    setLoginOption?.(loginOption);
    navigate(`/${currentLang}/login`);
  };

  const userId = userDetails ? userDetails.id : "Guest";
  const subscriptionType = userDetails ? userDetails.subscriptionType : "-";

  return (
    <div className={styles.userStatus}>
      <MobileMenu />
      <div className={styles.desktopLinks}>
        <NavigationLinks />
      </div>
      <div className={styles.leftSpacer}></div> {/* 여백을 채우는 요소 */}
      <div className={styles.userDetails}>
        <span>
          {userId} - {subscriptionType}
        </span>
      </div>
      <UserMenu
        userId={userId}
        subscriptionType={subscriptionType}
        handleLogin={handleLogin}
        handleLogout={handleLogout}
      />
    </div>
  );
};

export default UserStatus;
