import React, { useState } from "react";
import { UserContextType } from "../contexts/UserContext";
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { app } from "./firebase-config";
import { useUser } from "../contexts/UserContext";
import styles from "./InquiryCreate.module.css";
import { useNavigate } from "react-router-dom";
import { Typography, TextField, Button } from "@mui/material";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { useTranslation } from "react-i18next"; // 추가된 부분

function InquiryCreate() {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [content, setContent] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const userContext = useUser();
  if (userContext === null) {
    return null;
  }
  const currentLang = i18n.language; // 현재 설정된 언어 가져오기
  const { userDetails } = userContext as UserContextType;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!title.trim() || !content.trim()) {
      alert(t("inquiry_create.alert_fill_fields")); // 변경된 부분
      return;
    }

    const newInquiry = {
      authorId: userDetails.id,
      isAdmin: userDetails.userLevel <= 1,
      content: content,
      title: title,
      timestamp: serverTimestamp(),
    };

    try {
      const db = getFirestore(app);
      await addDoc(collection(db, "inquiries"), newInquiry);
      navigate(`/${currentLang}/inquiries`);
    } catch (error) {
      if (error instanceof Error) {
        console.error("Error writing new inquiry to Firebase Database", error);
        alert(t("inquiry_create.alert_fail") + error.message); // 변경된 부분
      } else {
        alert(t("inquiry_create.alert_fail")); // 변경된 부분
      }
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <div className={styles.container}>
      <Typography variant="h5" className={styles.title}>
        {t("inquiry_create.board_title")} {/* 변경된 부분 */}
      </Typography>
      <form onSubmit={handleSubmit} className={styles.form}>
        <TextField
          label={t("inquiry_create.title_label")} // 변경된 부분
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onKeyDown={handleKeyPress}
          className={styles.subjectField}
          fullWidth
          margin="normal"
        />

        <TextareaAutosize
          aria-label={t("inquiry_create.content_label")} // 변경된 부분
          white-space="pre-wrap"
          area-aria-multiline="true"
          minRows={15}
          placeholder={t("inquiry_create.content_placeholder")} // 변경된 부분
          className={styles.contentField}
          value={content}
          onChange={(e) => setContent(e.target.value)}
          style={{ resize: "none" }}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={styles.button}
        >
          {t("inquiry_create.submit_button")} {/* 변경된 부분 */}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          style={{ float: "right", marginTop: "10px" }}
          onClick={() => navigate(`/${currentLang}/inquiries`)}
        >
          {t("inquiry_create.back_button")} {/* 변경된 부분 */}
        </Button>
      </form>
    </div>
  );
}

export default InquiryCreate;
