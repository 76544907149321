import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import styles from "./JoinIn.module.css"; // CSS 모듈 파일 임포트
import { useUser } from "../contexts/UserContext"; // UserContext 사용
import { useTranslation } from "react-i18next"; // i18next 임포트

const JoinIn = () => {
  const { i18n, t } = useTranslation();
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [privacyAgreed, setPrivacyAgreed] = useState(false);
  const navigate = useNavigate();
  const { setLoginOption } = useUser() ?? {}; // Context에서 사용자 정보를 가져옴
  const currentLang = i18n.language; // 현재 설정된 언어 가져오기
  const handleRegisterClick = async () => {
    if (termsAgreed && privacyAgreed) {
      const loginOption = {
        isRegisterMode: true,
        isTermsOfServiceAgreed: true,
        isPrivacyPolicyAgreed: true,
      };
      setLoginOption?.(loginOption);
      try {
        console.log("Processing registration...");
        navigate(`/${currentLang}/Login`);
      } catch (error) {
        console.error("Registration failed:", error);
        alert(t("join_in.registration_failed")); // 변경된 부분
      }
    } else {
      alert(t("join_in.agree_to_all")); // 변경된 부분
    }
  };

  const handleCancelClick = () => {
    console.log("Registration cancelled.");
    const loginOption = {
      isRegisterMode: false,
      isTermsOfServiceAgreed: false,
      isPrivacyPolicyAgreed: false,
      userName: "",
      phoneNumber: "",
    };
    setLoginOption?.(loginOption);
    navigate(`/${currentLang}`);
  };

  return (
    <div className={styles.container}>
      <h2>{t("join_in.title")}</h2> {/* 변경된 부분 */}
      <div className={styles.textAreaContainer}>
        <TermsOfService />
      </div>
      <label className={styles.agreementLabel}>
        <input
          type="checkbox"
          checked={termsAgreed}
          onChange={(e) => setTermsAgreed(e.target.checked)}
        />
        {t("join_in.agree_terms")} {/* 변경된 부분 */}
      </label>
      <div className={styles.textAreaContainer}>
        <PrivacyPolicy />
      </div>
      <label className={styles.agreementLabel}>
        <input
          type="checkbox"
          checked={privacyAgreed}
          onChange={(e) => setPrivacyAgreed(e.target.checked)}
        />
        {t("join_in.agree_privacy")} {/* 변경된 부분 */}
      </label>
      <div className={styles.buttonGroup}>
        <button
          className={styles.button}
          onClick={handleRegisterClick}
          disabled={!termsAgreed || !privacyAgreed}
        >
          {t("join_in.register")} {/* 변경된 부분 */}
        </button>
        <button
          className={`${styles.button} ${styles.cancelButton}`}
          onClick={handleCancelClick}
        >
          {t("join_in.cancel")} {/* 변경된 부분 */}
        </button>
      </div>
    </div>
  );
};

export default JoinIn;
