import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./MobileMenu.module.css";
import { useUser } from "../contexts/UserContext";
import { useTranslation } from "react-i18next"; // i18next 임포트

const MobileMenu: React.FC = () => {
  const { i18n, t } = useTranslation();
  const currentLang = i18n.language; // 현재 설정된 언어 가져오기
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate(); // navigate 함수를 가져옵니다.
  const { userDetails } = useUser() ?? {};

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navigateTo = (path: string) => {
    setIsOpen(false); // 메뉴를 닫고
    navigate(`/${currentLang}${path}`);
  };

  const userId = userDetails ? userDetails.id : "Guest";

  return (
    <div className={styles.mobileMenu}>
      <button className={styles.menuButton} onClick={toggleMenu}>
        ☰
      </button>
      <div className={`${styles.menuLinks} ${isOpen ? styles.open : ""}`}>
        <button
          onClick={() => navigateTo("/")}
          className={styles.submenuButton}
        >
          {t("mobile_menu.home")} {/* 변경된 부분 */}
        </button>
        <button
          onClick={() => navigateTo("/download")}
          className={styles.submenuButton}
        >
          {t("mobile_menu.download")} {/* 변경된 부분 */}
        </button>
        <button
          onClick={() => navigateTo("/inquiries")}
          className={styles.submenuButton}
        >
          {t("mobile_menu.inquiries")} {/* 변경된 부분 */}
        </button>
        {userId !== "Guest" && (
          <button
            onClick={() => navigateTo("/task-management")}
            className={styles.submenuButton}
          >
            {t("mobile_menu.task_management")} {/* 변경된 부분 */}
          </button>
        )}
      </div>
    </div>
  );
};

export default MobileMenu;
