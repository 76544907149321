import React from "react";
import { useTranslation } from "react-i18next"; // i18next import
import styles from "./Footer.module.css";

const Footer = () => {
  const { t } = useTranslation(); // useTranslation 훅 사용

  return (
    <footer className={styles.footer}>
      <div className={styles["footer-row"]}>
        <p>
          <img src="/assets/images/wide_logo.png" alt="wide_logo" width={150} />
        </p>
        <p>{t("footer.copyright")}</p>
      </div>
    </footer>
  );
};

export default Footer;
