import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./TermsOfService.module.css"; // Import the styles

function TermsOfService() {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <h1 className={`${styles.header} ${styles.title}`}>
        {t("terms_of_service.title")}
      </h1>
      <p className={styles.text}>{t("terms_of_service.introduction")}</p>

      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("terms_of_service.section_1.title")}
      </h2>
      <p className={styles.text}>{t("terms_of_service.section_1.content")}</p>

      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("terms_of_service.section_2.title")}
      </h2>
      <p className={styles.text}>{t("terms_of_service.section_2.content")}</p>
      <ul>
        <li className={styles.item}>
          {t("terms_of_service.section_2.items.0")}
        </li>
        <li className={styles.item}>
          {t("terms_of_service.section_2.items.1")}
        </li>
        <li className={styles.item}>
          {t("terms_of_service.section_2.items.2")}
        </li>
      </ul>

      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("terms_of_service.section_3.title")}
      </h2>
      <p className={styles.text}>{t("terms_of_service.section_3.content")}</p>
      <ul>
        <li className={styles.item}>
          {t("terms_of_service.section_3.items.0")}
        </li>
        <li className={styles.item}>
          {t("terms_of_service.section_3.items.1")}
        </li>
        <li className={styles.item}>
          {t("terms_of_service.section_3.items.2")}
        </li>
      </ul>
      <p className={styles.text}>{t("terms_of_service.section_3.notice")}</p>

      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("terms_of_service.section_4.title")}
      </h2>
      <p className={styles.text}>{t("terms_of_service.section_4.content")}</p>

      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("terms_of_service.section_5.title")}
      </h2>
      <p className={styles.text}>{t("terms_of_service.section_5.content")}</p>
      <ul>
        <li className={styles.item}>
          {t("terms_of_service.section_5.items.0")}
        </li>
        <li className={styles.item}>
          {t("terms_of_service.section_5.items.1")}
        </li>
        <li className={styles.item}>
          {t("terms_of_service.section_5.items.2")}
        </li>
        <li className={styles.item}>
          {t("terms_of_service.section_5.items.3")}
        </li>
        <li className={styles.item}>
          {t("terms_of_service.section_5.items.4")}
        </li>
        <li className={styles.item}>
          {t("terms_of_service.section_5.items.5")}
        </li>
        <li className={styles.item}>
          {t("terms_of_service.section_5.items.6")}
        </li>
      </ul>

      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("terms_of_service.section_6.title")}
      </h2>
      <p className={styles.text}>{t("terms_of_service.section_6.content")}</p>
      <ul>
        <li className={styles.item}>
          {t("terms_of_service.section_6.items.0")}
        </li>
        <li className={styles.item}>
          {t("terms_of_service.section_6.items.1")}
        </li>
        <li className={styles.item}>
          {t("terms_of_service.section_6.items.2")}
        </li>
      </ul>

      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("terms_of_service.section_7.title")}
      </h2>
      <p className={styles.text}>{t("terms_of_service.section_7.content")}</p>

      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("terms_of_service.section_8.title")}
      </h2>
      <p className={styles.text}>{t("terms_of_service.section_8.content")}</p>

      <p className={styles.text}>{t("terms_of_service.effective_date")}</p>
    </div>
  );
}

export default TermsOfService;
