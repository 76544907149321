import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { UserContextType, useUser } from "../contexts/UserContext"; // UserContext 사용
import { useTranslation } from "react-i18next"; // i18next import
import styles from "./ReplyItem.module.css";

function ReplyItem({ reply, onDelete }: { reply: any; onDelete: any }) {
  const userContext = useUser(); // Remove type argument from useUser function call
  const { t } = useTranslation(); // useTranslation 훅 사용

  if (userContext === null) {
    return null; // or handle the null case
  }

  const { userDetails } = userContext as UserContextType;

  // 조건에 따라 답변 작성 버튼 렌더링
  const shouldShowDeleteButton =
    (userDetails.id !== "Guest" && userDetails.userLevel <= 1) ||
    userDetails.id === reply.authorId;

  // userLevel이 1이하이면 isAdmin이 true가 되도록.
  const isAdmin = userDetails.userLevel <= 1;

  let displayName: string;
  //admin이면 관리자로 표시
  if (isAdmin) {
    displayName = t("reply_item.admin");
  } else {
    displayName =
      reply.authorId.charAt(0) + "*".repeat(reply.authorId.length - 1);
  }

  return (
    <ListItem
      secondaryAction={
        shouldShowDeleteButton && ( // 조건부 렌더링
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => onDelete(reply.id)}
          >
            <CloseIcon />
          </IconButton>
        )
      }
    >
      <ListItemText
        primary={<div className={styles.content}>{reply.content}</div>}
        secondary={`${t("reply_item.reply_by")} ${displayName} ${t("reply_item.on")} ${reply.timestamp.toLocaleString()}`}
      />
    </ListItem>
  );
}

export default ReplyItem;
