// src/components/Login.js
import "./Login.css"; // CSS 파일 임포트
import React from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "./firebase-config"; // 이미 초기화된 auth 객체 사용
import { useTranslation } from "react-i18next"; // 추가된 부분
import {
  // GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
  signOut,
  setPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { useUser } from "../contexts/UserContext"; // UserContext 사용

const Login = () => {
  const navigate = useNavigate(); // 리디렉션을 위한 navigate 함수 사용
  const { loginOption, userDetails } = useUser() ?? {}; // Context에서 사용자 정보를 가져옴
  const { i18n } = useTranslation();
  const currentLang = i18n.language; // 현재 설정된 언어 가져오기
  // const handleEmailLogin = () => {
  //   // 이메일 로그인 페이지로 이동
  //   navigate("/login-email");
  // };

  // const signInWithGoogle = async () => {
  //   const provider = new GoogleAuthProvider();
  //   try {
  //     await signInWithPopup(auth, provider);
  //     navigate("/"); // Google 로그인 후 홈 페이지로 리디렉션
  //   } catch (error) {
  //     if (error instanceof Error) {
  //       alert(error.message);
  //     } else {
  //       alert("An error occurred while signing in with Google.");
  //     }
  //   }
  // };

  // const signInWithMicrosoft = async () => {
  //   const provider = new OAuthProvider("microsoft.com");
  //   try {
  //     // 로그인세션방식 변경
  //     alert("setPersistence");
  //     await setPersistence(auth, browserSessionPersistence);
  //     if (loginOption?.isRegisterMode || userDetails?.id === "Guest") {
  //       alert("go signout");
  //       await signOut(auth);
  //     }
  //     alert("signInWithPopup");
  //     await signInWithPopup(auth, provider);
  //     navigate("/"); // Microsoft 로그인 후 홈 페이지로 리디렉션
  //   } catch (error) {
  //     if (error instanceof Error) {
  //       alert(error.message);
  //     } else {
  //       alert("An error occurred while signing in with Microsoft.");
  //     }
  //   }
  // };

  const signInWithMicrosoftWWW = async () => {
    const provider = new OAuthProvider("microsoft.com");
    try {
      // 로그인세션방식 변경
      await setPersistence(auth, browserSessionPersistence);
      if (loginOption?.isRegisterMode || userDetails?.id === "Guest") {
        await signOut(auth);
        provider.setCustomParameters(
          // Microsoft 로그인 시 필요한 추가 정보
          {
            prompt: "select_account",
          }
        );
      }
      await signInWithPopup(auth, provider);
      navigate(`/${currentLang}`); // Microsoft 로그인 후 홈 페이지로 리디렉션
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert("An error occurred while signing in with Microsoft.");
      }
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        {loginOption?.isRegisterMode ? (
          <h1>AUTOVID Register</h1>
        ) : (
          <h1>AUTOVID Login</h1>
        )}
        <button
          className="login-button google-button"
          onClick={signInWithMicrosoftWWW}
        >
          <img src="/assets/images/microsoft.png" alt="Microsoft" />
          Sign in with Microsoft
        </button>
        {/* <button
          className="login-button google-button"
          onClick={signInWithGoogle}
        >
          <img src="/assets/images/google.png" alt="Google" />
          Sign in with Google
        </button>
        <button
          className="login-button email-button"
          onClick={handleEmailLogin}
        >
          <img src="/assets/images/mail.png" alt="Email" />
          Sign in with E-mail
        </button> */}
      </div>
    </div>
  );
};

export default Login;
