import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useCallback, useRef } from "react";
import Modal from "react-modal";
import styles from "./TaskManagement.module.css";
import {
  getTasks,
  updateTaskOrder,
  deleteTask,
  updateTaskStatus,
} from "./firebaseFunctions";
import { useUser } from "../contexts/UserContext"; // UserContext 사용
import { TaskStatus, TaskType, VideoTask } from "../types";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import LoadingSpinner from "./LoadingSpinner";
import classNames from "classnames";
import { useTranslation } from "react-i18next"; // i18next import

Modal.setAppElement("#root"); // 모달을 위한 앱 엘리먼트 설정

const taskTypeTranslations = {
  videoToVideo: "Video to Video",
  textToVideo: "Script to Video",
  bestSeries: "Best Series",
};

function GetContentStringFromTask(videoTask: VideoTask): string {
  let contentString = "";
  if (videoTask.taskType === TaskType.TextToVideo) {
    contentString = videoTask.textToVideo.theme;
  } else if (videoTask.taskType === TaskType.BestSeries) {
    contentString = videoTask.bestSeries.theme;
  } else {
    contentString = videoTask.videoToVideo.videoUrl;
  }
  return contentString;
}

const TaskManagement = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const currentLang = i18n.language; // 현재 설정된 언어 가져오기
  const [isLoading, setIsLoading] = useState(false);
  const { userDetails } = useUser() ?? {}; // Context에서 사용자 정보를 가져옴
  const [tasks, setTasks] = useState<VideoTask[]>(() => {
    // 로컬 스토리지에서 태스크를 불러옴
    const savedTasks = localStorage.getItem("tasks");
    return savedTasks ? JSON.parse(savedTasks) : [];
  });

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<number | null>(null); // 열려있는 모바일 메뉴의 인덱스를 관리
  const menuRef = useRef<HTMLDivElement>(null);

  const lastUpdatedRef = useRef(
    new Date(localStorage.getItem("lastUpdated") || 0)
  );

  const updateLastUpdated = () => {
    lastUpdatedRef.current = new Date();
    localStorage.setItem("lastUpdated", lastUpdatedRef.current.toISOString());
  };

  const handleRefreshTasks = async () => {
    try {
      setIsLoading(true);
      await fetchTasks();
    } catch (error) {
      console.error(t("task_management.fetch_task_failed"), error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTasks = useCallback(async () => {
    if (!userDetails) {
      return;
    }
    const now = new Date();
    try {
      const fetchedTasks = await getTasks(userDetails.id);
      setTasks(fetchedTasks);
      updateLastUpdated();

      localStorage.setItem("tasks", JSON.stringify(fetchedTasks));
      localStorage.setItem("lastUpdated", now.toISOString());
    } catch (error) {
      console.error(t("task_management.fetch_task_failed"), error);
    }
  }, [userDetails, t]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await fetchTasks();
      } catch (error) {
        console.error(t("task_management.fetch_task_failed"), error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      // Cleanup function
    };
  }, [fetchTasks, t]);

  const handleModifyTask = async (task: VideoTask) => {
    try {
      if (!userDetails) {
        return;
      }

      if (task.status === TaskStatus.Pending) {
        navigate(`/${currentLang}/task-management/edit`, {
          state: { orgTask: task },
        });
      } else if (task.status === TaskStatus.Failed) {
        setIsLoading(true);
        await updateTaskStatus(userDetails.id, task.id, TaskStatus.Pending);
        await fetchTasks(); // Refresh the list after deleting
        setIsLoading(false);
      } else {
        // do nothing
      }
    } catch (error) {
      console.error(t("task_management.fetch_task_failed"), error);
    }
  };

  const handleAddTask = async () => {
    try {
      if (!userDetails) {
        return;
      }

      if (!userDetails.channels || userDetails.channels.length === 0) {
        alert(t("task_management.no_channels_alert"));
        console.error(t("task_management.no_channels_alert"));
        return;
      }

      navigate(`/${currentLang}/task-management/add`);
    } catch (error) {
      console.error(t("task_management.fetch_task_failed"), error);
    }
  };

  const handleDeleteTask = async (task: VideoTask) => {
    try {
      if (!userDetails) {
        return;
      }
      const taskId = task.id;
      if (task.status !== TaskStatus.Completed) {
        if (!window.confirm(t("task_management.delete_confirm"))) {
          return;
        }
      }
      setIsMobileMenuOpen(null);

      setIsLoading(true);
      await deleteTask(userDetails.id, taskId);
      await fetchTasks();
    } catch (error) {
      console.error(t("task_management.delete_task_failed"), error);
      alert(t("task_management.delete_task_failed"));
    } finally {
      setIsLoading(false);
    }
  };

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
    if (!userDetails) {
      return;
    }

    const firstNonPendingIndex = tasks.findIndex(
      (task) => task.status !== TaskStatus.Pending
    );

    if (result.destination.index <= firstNonPendingIndex) {
      return;
    }

    try {
      setIsLoading(true);
      const newTasks = Array.from(tasks);
      const [reorderedItem] = newTasks.splice(result.source.index, 1);
      newTasks.splice(result.destination.index, 0, reorderedItem);

      if (newTasks.every((task, index) => task.id === tasks[index].id)) {
        return;
      }

      const docIds = newTasks.map((task) => task.id);

      setTasks(newTasks);
      await updateTaskOrder(userDetails.id, docIds);
    } catch (error) {
      console.error(t("task_management.fetch_task_failed"), error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !event.target ||
        (!(event.target as Element).closest(`.${styles.mobileMenuButton}`) &&
          !(event.target as Element).closest(`.${styles.mobileMenu}`))
      ) {
        setIsMobileMenuOpen(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  const toggleMobileMenu = (index: number) => {
    setIsMobileMenuOpen(isMobileMenuOpen === index ? null : index);
  };

  return (
    <div className={styles.container}>
      {isLoading && (
        <LoadingSpinner message={t("task_management.loading_message")} />
      )}
      <h1 className={styles.title}>{t("task_management.title")}</h1>
      <div className={styles.buttonContainer}>
        <button className={styles.button} onClick={handleAddTask}>
          {t("task_management.add_task")}
        </button>
        <button className={styles.button} onClick={handleRefreshTasks}>
          {t("task_management.refresh_list")}
        </button>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-tasks">
          {(provided) => (
            <div
              className={styles.tableContainer}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <Table className={styles.table} striped bordered hover>
                <thead>
                  <tr>
                    <th className={styles.thFitContent}>
                      {t("task_management.channel")}
                    </th>
                    <th className={styles.thFitContent}>
                      {t("task_management.task_type")}
                    </th>
                    <th className={styles.th}>{t("task_management.topic")}</th>
                    <th className={styles.thFitContent}>
                      {t("task_management.status")}
                    </th>
                    <th className={styles.thFitContent}>
                      {t("task_management.modify_delete")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tasks.map((task, index) => (
                    <Draggable
                      key={task.id}
                      draggableId={task.id}
                      index={index}
                      isDragDisabled={task.status !== TaskStatus.Pending}
                    >
                      {(provided, snapshot) => (
                        <tr
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          className={classNames({
                            [styles.draggingStyle]: snapshot.isDragging,
                            [styles.nonDraggingStyle]: !snapshot.isDragging,
                            [styles.draggable]:
                              task.status === TaskStatus.Pending,
                            [styles.noDraggable]:
                              task.status !== TaskStatus.Pending,
                          })}
                        >
                          <td
                            className={classNames({
                              [styles.tdFitContent]: true,
                              [styles.pendingTdFitContent]:
                                task.status === TaskStatus.Pending,
                              [styles.noPendingTdFitContent]:
                                task.status !== TaskStatus.Pending,
                            })}
                          >
                            <span>{task.channel}</span>
                          </td>
                          <td
                            className={classNames({
                              [styles.tdFitContent]: true,
                              [styles.pendingTdFitContent]:
                                task.status === TaskStatus.Pending,
                              [styles.noPendingTdFitContent]:
                                task.status !== TaskStatus.Pending,
                            })}
                          >
                            <span>{taskTypeTranslations[task.taskType]}</span>
                          </td>
                          <td
                            className={styles.td}
                            {...provided.dragHandleProps}
                          >
                            {GetContentStringFromTask(task)}
                          </td>
                          <td
                            className={classNames({
                              [styles.tdFitContent]: true,
                              [styles.pendingTdFitContent]:
                                task.status === TaskStatus.Pending,
                              [styles.noPendingTdFitContent]:
                                task.status !== TaskStatus.Pending,
                            })}
                          >
                            {t(`task_management.${task.status.toLowerCase()}`)}
                          </td>
                          <td
                            className={classNames({
                              [styles.tdFitContent]: true,
                              [styles.pendingTdFitContent]:
                                task.status === TaskStatus.Pending,
                              [styles.noPendingTdFitContent]:
                                task.status !== TaskStatus.Pending,
                            })}
                          >
                            <div className={styles.actionContainer}>
                              <button
                                className={styles.buttonModify}
                                disabled={
                                  isLoading ||
                                  (task.status !== TaskStatus.Pending &&
                                    task.status !== TaskStatus.Failed)
                                }
                                onClick={() => handleModifyTask(task)}
                              >
                                {t(
                                  `task_management.${
                                    task.status === TaskStatus.Pending ||
                                    task.status === TaskStatus.Completed
                                      ? "modify"
                                      : "retry"
                                  }`
                                )}
                              </button>

                              <button
                                className={styles.buttonDelete}
                                disabled={
                                  isLoading ||
                                  task.status === TaskStatus.InProgress
                                }
                                onClick={() => handleDeleteTask(task)}
                              >
                                {t(
                                  `task_management.${
                                    task.status === TaskStatus.Completed
                                      ? "confirm"
                                      : "delete"
                                  }`
                                )}
                              </button>
                              {/* 모바일 메뉴 버튼 */}
                              <button
                                className={styles.mobileMenuButton}
                                disabled={
                                  isLoading ||
                                  task.status === TaskStatus.InProgress
                                }
                                onClick={() => toggleMobileMenu(index)}
                              >
                                ...
                              </button>
                              {isMobileMenuOpen === index && (
                                <div
                                  ref={menuRef}
                                  className={styles.mobileMenu}
                                >
                                  <button
                                    className={styles.subButtonModify}
                                    disabled={
                                      isLoading ||
                                      (task.status !== TaskStatus.Pending &&
                                        task.status !== TaskStatus.Failed)
                                    }
                                    onClick={() => handleModifyTask(task)}
                                  >
                                    {t(
                                      `task_management.${
                                        task.status === TaskStatus.Pending ||
                                        task.status === TaskStatus.Completed
                                          ? "modify"
                                          : "retry"
                                      }`
                                    )}
                                  </button>
                                  <button
                                    className={styles.subButtonDelete}
                                    onClick={() => handleDeleteTask(task)}
                                  >
                                    {t(
                                      `task_management.${
                                        task.status === TaskStatus.Completed
                                          ? "confirm"
                                          : "delete"
                                      }`
                                    )}
                                  </button>
                                </div>
                              )}
                            </div>
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tbody>
              </Table>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {lastUpdatedRef.current && (
        <p className={styles.lastUpdated}>
          {t("task_management.last_updated")}:{" "}
          {lastUpdatedRef.current.toLocaleTimeString()}
        </p>
      )}
    </div>
  );
};

export default TaskManagement;
