import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UserContextType } from "../contexts/UserContext";
import {
  List,
  ListItem,
  Typography,
  Divider,
  Button,
  Grid,
} from "@mui/material";
import {
  QueryDocumentSnapshot,
  DocumentData,
  getFirestore,
  collection,
  limit,
  startAfter,
  getDocs,
  Timestamp,
  query,
  orderBy,
} from "firebase/firestore";

import CustomPagination from "./CustomPagination";
import { useUser } from "../contexts/UserContext";
import { app } from "./firebase-config";
import styles from "./InquiryBoard.module.css";
import { useTranslation } from "react-i18next"; // i18next import

const InquiryBoard = () => {
  const { t } = useTranslation(); // useTranslation hook 초기화
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [inquiries, setInquiries] = useState<Inquiry[]>([]);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<
    DocumentData,
    DocumentData
  > | null>(null);
  const [prevLastVisible, setPrevLastVisible] = useState<QueryDocumentSnapshot<
    DocumentData,
    DocumentData
  > | null>(null);

  const userContext = useUser();

  type Inquiry = {
    id: string;
    title: string;
    authorId: string;
    timestamp: Timestamp;
    numReplies: number;
  };

  const ITEMCOUNT_PER_PAGE = 15;
  const handlePageChange = (value: number) => {
    setLastPage(page);
    setPage(value);
    if (value === 1) {
      setLastVisible(null);
    }
  };

  useEffect(() => {
    if (userContext === null) {
      return;
    }
    const fetchInquiries = async () => {
      setLoading(true);
      const db = getFirestore(app);
      let inquiriesRef = null;
      if (page === 1) {
        inquiriesRef = query(
          collection(db, "inquiries"),
          orderBy("timestamp", "desc"),
          limit(ITEMCOUNT_PER_PAGE)
        );
      } else if (lastPage > page) {
        if (prevLastVisible !== null) {
          inquiriesRef = query(
            collection(db, "inquiries"),
            orderBy("timestamp", "desc"),
            startAfter(prevLastVisible),
            limit(ITEMCOUNT_PER_PAGE)
          );
        } else {
          inquiriesRef = query(
            collection(db, "inquiries"),
            orderBy("timestamp", "desc"),
            limit(ITEMCOUNT_PER_PAGE)
          );
        }
      } else {
        inquiriesRef = query(
          collection(db, "inquiries"),
          orderBy("timestamp", "desc"),
          startAfter(lastVisible),
          limit(ITEMCOUNT_PER_PAGE)
        );
      }

      try {
        const querySnapshot = await getDocs(inquiriesRef);
        if (!querySnapshot.empty) {
          const lastVisibleDoc =
            querySnapshot.docs[querySnapshot.docs.length - 1];
          if (page > 1 && lastVisibleDoc !== prevLastVisible) {
            setPrevLastVisible(lastVisible);
          }

          setLastVisible(
            lastVisibleDoc as QueryDocumentSnapshot<DocumentData, DocumentData>
          );

          const inquiriesDataPromises = querySnapshot.docs.map(async (doc) => {
            const repliesRef = collection(doc.ref, "replies");
            const replySnapshot = await getDocs(repliesRef);
            let displayName: string;
            if (doc.data().isAdmin) {
              displayName = t("inquiryBoard.admin"); // '관리자' 번역
            } else {
              displayName =
                doc.data().authorId.charAt(0) +
                "*".repeat(doc.data().authorId.length - 1);
            }
            return {
              id: doc.id,
              title: doc.data().title,
              authorId: displayName,
              timestamp: doc.data().timestamp,
              numReplies: replySnapshot.size,
            };
          });

          const inquiriesData = await Promise.all(inquiriesDataPromises);
          setInquiries(inquiriesData);
        } else {
          if (page !== 1) {
            setPage(lastPage);
          }
        }
      } catch (err) {
        console.error("Error fetching inquiries:", err);
        setError(t("inquiryBoard.errorText")); // 에러 메시지 번역
      } finally {
        setLoading(false);
      }
    };
    fetchInquiries();
  }, [page, lastPage, lastVisible, prevLastVisible, userContext, t]);

  if (loading) {
    return (
      <Typography className={styles.loadingText}>
        {t("inquiryBoard.loadingText")} {/* 로딩 텍스트 번역 */}
      </Typography>
    );
  }

  if (error) {
    return (
      <Typography className={styles.loadingText} color="error">
        {error}
      </Typography>
    );
  }
  let shouldShowWriteButton: boolean = false;

  if (userContext !== null) {
    const { userDetails } = userContext as UserContextType;
    shouldShowWriteButton = userDetails.id !== "Guest";
  }

  return (
    <div className={styles.container}>
      <Typography variant="h5" className={styles.title}>
        {t("inquiryBoard.title")} {/* 문의 게시판 제목 번역 */}
      </Typography>
      <Grid container spacing={1} alignItems="center" className={styles.header}>
        <Grid item xs={5}>
          <Typography
            variant="subtitle1"
            align="left"
            className={styles.header}
          >
            {t("inquiryBoard.header.title")} {/* 제목 번역 */}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            variant="subtitle1"
            align="left"
            className={styles.header}
          >
            {t("inquiryBoard.header.date")} {/* 날짜 번역 */}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            variant="subtitle1"
            align="left"
            className={styles.header}
          >
            {t("inquiryBoard.header.author")} {/* 작성자 번역 */}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography
            variant="subtitle1"
            align="left"
            className={styles.header}
          >
            {t("inquiryBoard.header.numReplies")} {/* 답변수 번역 */}
          </Typography>
        </Grid>
      </Grid>
      <List className={styles.list}>
        {inquiries.map((inquiry) => (
          <React.Fragment key={inquiry.id}>
            <ListItem
              component={Link}
              to={`/inquiries/${inquiry.id}`}
              className={styles.listItem}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={5}>
                  <Typography
                    variant="body2"
                    align="left"
                    className={styles.listItemTitle}
                  >
                    {inquiry.title}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    variant="body2"
                    align="left"
                    className={styles.listItemDate}
                  >
                    {new Date(inquiry.timestamp.toDate()).toLocaleString(
                      "default",
                      {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: false,
                      }
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    variant="body2"
                    align="left"
                    className={styles.listItemTitle}
                  >
                    {inquiry.authorId}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    variant="body2"
                    align="left"
                    className={styles.typography}
                  >
                    {inquiry.numReplies > 0
                      ? `${inquiry.numReplies}`
                      : t("inquiryBoard.noReplies")}{" "}
                    {/* 답변 수 번역 */}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
      <CustomPagination page={page} onPageChange={handlePageChange} />
      {shouldShowWriteButton && (
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/inquiries/new"
          className={styles.button}
        >
          {t("inquiryBoard.writeNewInquiryButton")}{" "}
          {/* 새 문의 작성 버튼 번역 */}
        </Button>
      )}
    </div>
  );
};

export default InquiryBoard;
