import React from "react";
import ReactLoading from "react-loading";
import styles from "./LoadingSpinner.module.css";

interface LoadingSpinnerProps {
  message: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ message }) => {
  return (
    <div className={styles.overlay}>
      <div className={styles.spinnerContainer}>
        <ReactLoading
          type={"spin"}
          color={"#007bff"}
          height={"80%"}
          width={"80%"}
        />
        <p className={styles.message}>{message}</p>
      </div>
    </div>
  );
};

export default LoadingSpinner;
