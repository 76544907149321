// TimeInput.tsx
import React from "react";
import styles from "./TimeInput.module.css";

interface TimeInputProps {
  value: Date;
  onChange: (newTime: Date) => void;
}

const TimeInput: React.FC<TimeInputProps> = ({ value, onChange }) => {
  const handleTimeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const newTime = new Date(value);
    const timeValue = parseInt(event.target.value, 10);

    if (isNaN(timeValue)) return;

    switch (type) {
      case "hours":
        newTime.setHours(timeValue);
        break;
      case "minutes":
        newTime.setMinutes(timeValue);
        break;
      case "seconds":
        newTime.setSeconds(timeValue);
        break;
      default:
        break;
    }
    onChange(newTime);
  };

  return (
    <div>
      <input
        title="Hours"
        placeholder="HH"
        type="number"
        value={value.getHours()}
        onChange={(e) => handleTimeChange(e, "hours")}
        min="0"
        max="23"
        className={styles.input}
      />
      :
      <input
        title="Minutes"
        placeholder="MM"
        type="number"
        value={value.getMinutes()}
        onChange={(e) => handleTimeChange(e, "minutes")}
        min="0"
        max="59"
        className={styles.input}
      />
      :
      <input
        title="Seconds"
        placeholder="SS"
        type="number"
        value={value.getSeconds()}
        onChange={(e) => handleTimeChange(e, "seconds")}
        min="0"
        max="59"
        className={styles.input}
      />
    </div>
  );
};

export default TimeInput;
