// src/firebaseFunctions.ts
//import firebase from "./firebase-config"; // Firebase 초기 설정을 가져옵니다.
//import "firebase/functions"; // Firebase Functions를 가져옵니다.
import {
  UserDataResponse,
  UserDataRequestParam,
  VideoTask,
  TaskStatus,
} from "../types"; // 타입 정의를 가져옵니다.
import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
// import { app } from "./firebase-config"; // Firebase 초기 설정을 가져옵니다.

// Firebase Function 호출 함수
// getTasks 함수가 Promise를 반환하도록 수정
export const getTasks = async (userId: string): Promise<VideoTask[]> => {
  const functions = getFunctions(getApp(), "asia-northeast3");
  const getTasksFn = httpsCallable(functions, "getTasksOnCall");

  try {
    const response = await getTasksFn({ userId });
    const tasks = response.data as VideoTask[];
    return tasks;
  } catch (error: any) {
    console.error("Error calling getTasks function:", error.message);
    throw error;
  }
};

export const updateTaskOrder = async (userId: string, docIds: string[]) => {
  const functions = getFunctions(getApp(), "asia-northeast3");
  const updateTaskOrderFn = httpsCallable(functions, "updateTaskOrder");

  try {
    const response = await updateTaskOrderFn({ userId, docIds });
    return response.data; // 작업 순서 업데이트 성공 메시지
  } catch (error: any) {
    console.error("Error calling updateTaskOrder function:", error.message);
    throw error;
  }
};

export const addTask = async (userId: string, taskDetails: VideoTask) => {
  const functions = getFunctions(getApp(), "asia-northeast3");
  const addTaskFn = httpsCallable(functions, "addTask");
  try {
    const response = await addTaskFn({ userId, taskDetails });
    return response.data; // 작업 추가 성공 메시지
  } catch (error: any) {
    console.error("Error adding task:", error.message);
    throw error;
  }
};

export const updateChannels = async (userId: string, channels: string[]) => {
  const functions = getFunctions(getApp(), "asia-northeast3");
  const updateChannelsFn = httpsCallable(functions, "updateChannels");
  try {
    const response = await updateChannelsFn({ userId, channels });
    return response.data; // 작업 업데이트 성공 메시지
  } catch (error: any) {
    console.error("Error updating task:", error.message);
    throw error;
  }
};

export const updateTask = async (
  userId: string,
  taskId: string,
  taskDetails: VideoTask
) => {
  const functions = getFunctions(getApp(), "asia-northeast3");
  const updateTaskFn = httpsCallable(functions, "updateTaskOnCall");
  try {
    const response = await updateTaskFn({ userId, taskId, taskDetails });
    return response.data; // 작업 업데이트 성공 메시지
  } catch (error: any) {
    console.error("Error updating task:", error.message);
    throw error;
  }
};

export const updateTaskStatus = async (
  userId: string,
  taskId: string,
  status: TaskStatus
) => {
  const functions = getFunctions(getApp(), "asia-northeast3");
  const updateTaskStatusFn = httpsCallable(functions, "updateTaskStatusOnCall");
  try {
    const response = await updateTaskStatusFn({ userId, taskId, status });
    return response.data; // 작업 상태 업데이트 성공 메시지
  } catch (error: any) {
    console.error("Error updating task status:", error.message);
    throw error;
  }
};

export const deleteTask = async (userId: string, taskId: string) => {
  const functions = getFunctions(getApp(), "asia-northeast3");
  const deleteTaskFn = httpsCallable(functions, "deleteTask");
  try {
    const response = await deleteTaskFn({ userId, taskId });
    return response.data; // 작업 삭제 성공 메시지
  } catch (error: any) {
    console.error("Error deleting task:", error.message);
    throw error;
  }
};

export const getUserData = async (
  uid: string,
  userId: string
): Promise<UserDataResponse> => {
  const functions = getFunctions(getApp(), "asia-northeast3");
  const getUserDataFn = httpsCallable(functions, "getAutoVidUserDataOnCall");
  try {
    const requestParam = {
      uid: uid,
      userId: userId,
      deleteAuthWhenNotJoined: true,
    } as UserDataRequestParam;
    const response = await getUserDataFn({
      userdata: requestParam,
    });
    return response.data as UserDataResponse; // 반환 타입을 명시적으로 캐스팅
  } catch (error: any) {
    if (error.code === "functions/unauthenticated") {
      throw new Error("인증되지 않은 사용자입니다.");
    } else if (error.code === "functions/not-found") {
      throw new Error("회원가입을 먼저 진행해주세요.");
    } else {
      console.error("Error calling getUserData function:", error.message);
      throw error;
    }
  }
};

export const withdrawMember = async (userId: string) => {
  const functions = getFunctions(getApp(), "asia-northeast3");
  const withdrawAutoVidMemberFn = httpsCallable(
    functions,
    "withdrawAutoVidMember"
  );
  try {
    // const requestParam = {
    //   uid: uid,
    //   userId: userId } as UserDataRequestParam;
    await withdrawAutoVidMemberFn({
      userId: userId,
    });
    return; // 반환 타입을 명시적으로 캐스팅
  } catch (error: any) {
    throw error;
  }
};

export const registerMember = async (
  userId: string
): Promise<UserDataResponse> => {
  const functions = getFunctions(getApp(), "asia-northeast3");
  const registerMemberFn = httpsCallable(
    functions,
    "registerAutoVidMemberOnCall"
  );
  try {
    const response = await registerMemberFn({
      userId: userId,
    });
    return response.data as UserDataResponse; // 반환 타입을 명시적으로 캐스팅
  } catch (error: any) {
    if (error.code === "functions/already-exists") {
      throw new Error("이미 가입된 사용자입니다.");
    } else {
      console.error("Error calling getUserData function:", error.message);
      throw error;
    }
  }
};
