import React from "react";
import { useTranslation } from "react-i18next"; // Import useTranslation hook
import styles from "./PrivacyPolicy.module.css"; // Import the styles

function PrivacyPolicy() {
  const { t } = useTranslation(); // Initialize the translation hook

  return (
    <div className={styles.container}>
      <h1 className={`${styles.header} ${styles.title}`}>
        {t("privacy_policy.title")}
      </h1>
      <p className={styles.text}>{t("privacy_policy.introduction.text1")}</p>
      <p className={styles.text}>{t("privacy_policy.introduction.text2")}</p>
      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("privacy_policy.section1.title")}
      </h2>
      <p className={styles.text}>{t("privacy_policy.section1.text1")}</p>
      <p className={styles.text}>{t("privacy_policy.section1.text2")}</p>
      <h3 className={`${styles.header} ${styles.sectionTitle}`}>
        {t("privacy_policy.section1_1.title")}
      </h3>
      <p className={styles.text}>{t("privacy_policy.section1_1.text")}</p>
      <h3 className={`${styles.header} ${styles.sectionTitle}`}>
        {t("privacy_policy.section1_2.title")}
      </h3>
      <p className={styles.text}>{t("privacy_policy.section1_2.text")}</p>
      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("privacy_policy.section2.title")}
      </h2>
      <p className={styles.text}>{t("privacy_policy.section2.text1")}</p>
      <p className={styles.text}>{t("privacy_policy.section2.text2")}</p>
      <h3 className={`${styles.header} ${styles.sectionTitle}`}>
        {t("privacy_policy.section2.item1")}
      </h3>
      <p className={styles.text}>{t("privacy_policy.section2.item1_1")}</p>
      <p className={styles.text}>{t("privacy_policy.section2.item1_2")}</p>
      <h3 className={`${styles.header} ${styles.sectionTitle}`}>
        {t("privacy_policy.section2.item2")}
      </h3>
      <p className={styles.text}>{t("privacy_policy.section2.item2_1")}</p>
      <p className={styles.text}>{t("privacy_policy.section2.item2_2")}</p>
      <p className={styles.text}>{t("privacy_policy.section2.item2_3")}</p>
      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("privacy_policy.section3.title")}
      </h2>
      <p className={styles.text}>{t("privacy_policy.section3.text")}</p>
      <h3 className={`${styles.header} ${styles.sectionTitle}`}>
        {t("privacy_policy.section3.item1")}
      </h3>
      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("privacy_policy.section4.title")}
      </h2>
      <p className={styles.text}>{t("privacy_policy.section4.text1")}</p>
      <ul className={styles.list}>
        <li className={styles.item}>{t("privacy_policy.section4.item1")}</li>
      </ul>
      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("privacy_policy.section5.title")}
      </h2>
      <p className={styles.text}>{t("privacy_policy.section5.text1")}</p>
      <p className={styles.text}>{t("privacy_policy.section5.text2")}</p>
      <p className={styles.text}>{t("privacy_policy.section5.text3")}</p>
      <p className={styles.text}>{t("privacy_policy.section5.text3_1")}</p>
      <p className={styles.text}>{t("privacy_policy.section5.text3_2")}</p>
      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("privacy_policy.section6.title")}
      </h2>
      <p className={styles.text}>{t("privacy_policy.section6.text1")}</p>
      <p className={styles.text}>{t("privacy_policy.section6.text2")}</p>
      <p className={styles.text}>{t("privacy_policy.section6.text3")}</p>
      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("privacy_policy.section7.title")}
      </h2>
      <p className={styles.text}>{t("privacy_policy.section7.text1")}</p>
      <ul className={styles.list}>
        <li className={styles.item}>{t("privacy_policy.section7.item1")}</li>
        <li className={styles.item}>{t("privacy_policy.section7.item2")}</li>
        <li className={styles.item}>{t("privacy_policy.section7.item3")}</li>
        <li className={styles.item}>{t("privacy_policy.section7.item4")}</li>
        <li className={styles.item}>{t("privacy_policy.section7.item5")}</li>
      </ul>
      <h3 className={`${styles.header} ${styles.sectionTitle}`}>
        {t("privacy_policy.section8.title")}
      </h3>
      <p className={styles.text}>{t("privacy_policy.section8.text1")}</p>
      <ul className={styles.list}>
        <li className={styles.item}>{t("privacy_policy.section8.item1")}</li>
        <li className={styles.item}>{t("privacy_policy.section8.item2")}</li>
        <li className={styles.item}>{t("privacy_policy.section8.item3")}</li>
      </ul>
      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("privacy_policy.section9.title")}
      </h2>
      <p className={styles.text}>{t("privacy_policy.section9.text")}</p>
      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("privacy_policy.section10.title")}
      </h2>
      <p className={styles.text}>{t("privacy_policy.section10.text")}</p>
      <ul className={styles.list}>
        <li className={styles.item}>{t("privacy_policy.section10.item1")}</li>
        <li className={styles.item}>{t("privacy_policy.section10.item2")}</li>
        <li className={styles.item}>{t("privacy_policy.section10.item3")}</li>
      </ul>
      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("privacy_policy.section11.title")}
      </h2>
      <p className={styles.text}>{t("privacy_policy.section11.text1")}</p>
      <h3 className={`${styles.header} ${styles.sectionTitle}`}>
        {t("privacy_policy.section11.text2")}
      </h3>
      <p className={styles.text}>{t("privacy_policy.section11.text3")}</p>
      <p className={styles.text}>{t("privacy_policy.section11.text4")}</p>
      <p className={styles.text}>{t("privacy_policy.section11.text5")}</p>
      <p className={styles.text}>{t("privacy_policy.section11.text6")}</p>
      <p className={styles.text}>{t("privacy_policy.section11.text7")}</p>
      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("privacy_policy.section12.title")}
      </h2>
      <h3 className={`${styles.header} ${styles.sectionTitle}`}>
        {t("privacy_policy.section12.text")}
      </h3>
      <ul className={styles.list}>
        <li className={styles.item}>{t("privacy_policy.section12.item1")}</li>
        <li className={styles.item}>{t("privacy_policy.section12.item2")}</li>
        <li className={styles.item}>{t("privacy_policy.section12.item3")}</li>
        <li className={styles.item}>{t("privacy_policy.section12.item4")}</li>
        <li className={styles.item}>{t("privacy_policy.section12.item5")}</li>
        <li className={styles.item}>{t("privacy_policy.section12.item6")}</li>
      </ul>
      <h2 className={`${styles.header} ${styles.subTitle}`}>
        {t("privacy_policy.section13.title")}
      </h2>
      <p className={styles.text}>{t("privacy_policy.section13.text1")}</p>
      <p className={styles.text}>{t("privacy_policy.section13.text2")}</p>
      <p className={styles.text}>{t("privacy_policy.section13.text3")}</p>
    </div>
  );
}

export default PrivacyPolicy;
